<template>
    <!--header-->
    <header class="header header--js text-center">
        <div class="header__lab relative d-flex">
            <button @click="toggleBodyClass" type="button" class="button-menu">
                <span class="hide-active"></span>
                <span class="rotate-1"></span>
                <span class="rotate-2"></span>
                <span class="hide-active"></span>
            </button>
            <img class="logo align-self-center mx-auto" alt="Iqos Pocket" src="@/assets/logo.png" />
        </div>

        <div @click.stop="removeClass" class="header__expand">
            <div class="header__container">
                <div class="row my-5" @click.stop="toggle('contact')">
                    <div class="col-dropdown-1">
                        <span>Contact</span>
                    </div>
                    <div class="col-dropdown-2">
                        <i :class="contact == 0?'arrow arrow-down':'arrow arrow-up'"></i>
                    </div>
                    <div v-if="contact == 1" class="my-3 accordion-content">
                        <img class="my-3" alt src="@/assets/icon-tel.svg" />
                        <h3 class>Mai ai întrebări?</h3>
                        <h3 class>Suntem aici pentru tine</h3>
                        <p class="font-planer-light mb-1 mt-4">Program: zilnic, între 08:00- 22:00</p>
                        <a href="tel:0800030333">0800030333</a>
                    </div>
                </div>

                <!--btn faq hidden if path is /faq-->
                <div v-if="$route.path != '/faq'">
                    <hr style="background-color:black;" />
                    <div class="row my-5" @click.stop="toggle('intrebari')">
                        <div class="col-dropdown-1">
                            <span class="font-weight-bold">Întrebări frecvente</span>
                        </div>
                        <div class="col-dropdown-2">
                            <i :class="intrebari == 0?'arrow arrow-down':'arrow arrow-up'"></i>
                        </div>
                        <div v-if="intrebari == 1" class="mt-3 accordion-content accordion-content--menu-faq" @click="removeClass">
                            <router-link to="/faq#iqos3" class="accordion-dev">IQOS 3</router-link>
                            <router-link to="/faq#multi" class="accordion-dev">IQOS 3 Multi</router-link>
                            <router-link to="/faq#iqos24" class="accordion-dev">IQOS 2.4 +</router-link>
                            <router-link to="/faq#despre" class="accordion-dev">Despre IQOS</router-link>
                        </div>
                    </div>
                </div>
                <!--btn faq hidden if path is /faq-->

            </div>
        </div>
    </header>
    <!--end header-->
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            drawer: false,
            contact: 0,
            intrebari: 0
        };
    },
    methods: {
        ...mapActions({
            backToVideos: "backToVideos"
        }),

        toggle: function(event) {
            this[event] = !this[event];
        },
        toggleBodyClass() {
            var appBody = document.querySelector("body"),
                btnMenu = document.querySelector(".button-menu");
            appBody.classList.toggle("body-menu-active");
            btnMenu.classList.toggle("active");
        },
        removeClass() {
            var appBody = document.querySelector("body"),
                btnMenu = document.querySelector(".button-menu");
            appBody.classList.remove("body-menu-active");
            btnMenu.classList.remove("active");
        }
        // hasClass(ele,cls) {
        //     return ele.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)'));
        // }
    },
    mounted() {
        //var $this = this;
        // var appBody = document.querySelector('body');
        // document.addEventListener('click', function(){
        //     appBody.classList.remove('body-menu-active');
        //     console.log('ceva');
        // });
        //$this.hasClass();
        // if($this.hasClass(document.body.classList.contains('body-menu-active'))){
        //     console.log('are');
        // }
    }
};
</script>
